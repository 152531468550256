import axios from "axios";
const { REACT_APP_BASE_URL } = process.env;

const API_TIMEOUT = 6000

const httpClient = axios.create({
    withCredentials: true,
    baseURL: REACT_APP_BASE_URL,
    headers: { Accept: 'application/json' },
    timeout: API_TIMEOUT
});

export const httpUploadClient = axios.create({
    withCredentials: true,
    baseURL: REACT_APP_BASE_URL,
    headers: { Accept: 'text/plain' },
    timeout: API_TIMEOUT * 2
});

export default httpClient