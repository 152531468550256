export enum Routes {
    LOGIN = '/login',
    AUTH = '/auth',
    CHANGE_PASSWORD = '/change-password',
    USERS = '/users',
    QUIZ = '/quiz',
    ALL = '/all',
    MANAGE = '/manage',
    CATEGORIES = '/categories',
    ID = '/:id',
    SCHEDULER = '/scheduler',
    CALENDAR = '/calendar',
    NEW_MEETING = '/new-meeting',
    NEW_EVENT = '/new-event',
    EDIT_EVENT = '/edit-event',
    PREVIEW_INVITATION = '/preview-invitation',
    INVITATION = '/invitation',
    HUDDLE = '/trivio'
}