import { IAppState } from "../../../types";
import { Quizzes, QuizzesActions } from "../../../types/Quizzes";
import { all, call, put, select } from "redux-saga/effects";
import { v4 as uuid } from 'uuid';
import {
    authLogout,
    closeModal,
    getAllCategories,
    getCategories,
    getQuizzes,
    hideLoader,
    navClearStore,
    navGoTo,
    setCategories,
    setGameCode,
    setPendingQuestions,
    setPendingRewards,
    setQuestions,
    setQuiz,
    setQuizzes,
    setTempImage,
    showLoader,
    showSnackbar
} from "../../../redux/actions";
import { handleGenerateError, handleQueryParamsData, quizzesApiRequest, imageApiRequest } from "../../../redux/api";
import { questionMapper, quizDetailsMapper, rewardMapper } from "../../../redux/dataMappers";
import { routes } from "../../../config";
import { ModalsEnum } from "../../../types/Enums";

const MESSAGES = {
    success: {
        create: 'Quiz Created',
        createCat: 'Category Created',
        createQuestion: 'Question Created',
        update: 'Quiz Updated',
        updateCat: 'Category Updated',
        updateQuestion: 'Question Updated',
        delete: 'Quiz Deleted',
        deleteCat: 'Category Deleted',
        deleteQuestion: 'Question Deleted',
        enable: 'Quiz Enabled',
        disable: 'Quiz Disabled',
        reorder: 'Quiz Reordered'
    },
    error: {
        getAll: 'Unable to load quizzes.',
        getQuiz: 'Unable to load quiz.',
        getCategories: 'Unable to load quiz categories.',
        reorder: 'Couldn\'t reorder quiz questions.'
    }
};

export function* pendingQuestionsSaga(action: Quizzes.TActions.UpdatePendingQuestions) {
    const pendingQuestions = yield select(pendingQuestionsSelector);
    const quiz = yield select(quizSelector);
    const { payload, meta } = action;

    /* if (payload.id && payload.id?.indexOf('TEMP') > -1 && quiz && quiz.questions) {
         const questions = quiz.questions;
         questions[meta.idx] = payload;
         yield put(setQuestions(questions, meta.huddle));
     } else {*/
    const storeQuestion = questionMapper.stateToStore(payload);
    if (pendingQuestions.length === 0) {
        if (meta.command === 'add' || meta.command === 'update') {
            yield put(setPendingQuestions([storeQuestion], meta.huddle));
        }
    } else {
        const update = [...pendingQuestions];
        if (meta.command === 'add') {
            update.splice(meta.idx + 1, 0, storeQuestion);
        } else if (meta.command === 'remove') {
            update.splice(meta.idx, 1);
        } else if (meta.command === 'update') {
            update[meta.idx] = storeQuestion;
        }
        yield put(setPendingQuestions(update, meta.huddle));
    }
    //}
}

export function* pendingRewardsSaga(action: Quizzes.TActions.UpdatePendingRewards) {
    const pendingRewards = yield select(pendingRewardsSelector);
    const { payload, meta } = action;

    const storeReward = rewardMapper.stateToStore(payload);
    if (pendingRewards.length === 0) {
        if (meta.command === 'add' || meta.command === 'update') {
            yield put(setPendingRewards([storeReward], meta.huddle));
        }
    } else {
        const update = [...pendingRewards];
        if (meta.command === 'add') {
            update.splice(meta.idx + 1, 0, storeReward);
        } else if (meta.command === 'remove') {
            update.splice(meta.idx, 1);
        } else if (meta.command === 'update') {
            update[meta.idx] = storeReward;
        }
        yield put(setPendingRewards(update, meta.huddle));
    }
}

export function* quizzesAPISaga(action: Quizzes.TActions.APIActions) {
    try {
        if (action.type === QuizzesActions.GET_GAMECODE) {
            yield put(showLoader('quiz'));
            const response = yield call(quizzesApiRequest, {
                method: 'POST',
                url: `/game/definition/${action.payload.id}/instance`,
                payload: {
                    isHuddle: action.payload.huddle
                }
            });
            yield put(setGameCode(response.data.gameCode));
            yield put(hideLoader('quiz'));
        } else if (action.type === QuizzesActions.SET_AVATAR) {
            yield put(showLoader('image-upload'));
            const formData = new FormData();
            formData.append('file', action.payload);
            const response = yield call(imageApiRequest, {
                method: 'POST',
                url: `content/file/upload?fileType=GameAvatar`,
                payload: formData
            });
            yield put(setTempImage(response.data.url));
            yield put(hideLoader('image-upload'));
        } else if (action.type === QuizzesActions.GET_QUIZ) {
            yield put(showLoader('quiz'));
            const response = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/definition/${action.payload.id}?isHuddle=${action.payload.huddle}&sortBy=-createdOnUtc`
            });
            yield put(setQuiz(response.data, action.payload.huddle));
            yield put(setPendingQuestions(response.data.questions, action.payload.huddle));
            const tmpRewards = [response.data.rewardsConfiguration?.hostReward].concat(response.data.rewardsConfiguration?.guestRewards);
            const pendingRewards: any = tmpRewards.filter(reward => reward !== undefined).map(reward => {
                reward.id = uuid();
                return reward;
            });
            yield put(setPendingRewards(pendingRewards, action.payload.huddle));
            yield put(hideLoader('quiz'));
        } else if (action.type === QuizzesActions.GET) {
            yield put(showLoader('quizzes-table'));
            const params = handleQueryParamsData<Quizzes.TQuizSortFileds>(action.payload.params);
            const response = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/definition?${params}&isHuddle=${action.payload.huddle}&sortBy=-createdOnUtc`
            });
            yield put(setQuizzes(response.data, action.payload.huddle));
            yield put(hideLoader('quizzes-table'));
        } else if (action.type === QuizzesActions.CREATE) {
            const { name, category, description, avatarUrl, isPublic } = quizDetailsMapper.stateToStore(action.payload.fields);
            const questions = yield select(pendingQuestionsSelector);
            const pendingRewards = yield select(pendingRewardsSelector);
            const rewardsConfiguration = {
                hostReward: pendingRewards[0],
                guestRewards: pendingRewards.splice(1, pendingRewards.length)
            };
            const apiPayload = { questions, rewardsConfiguration, name, category, description, avatarUrl, passFailPercent: 10, isHuddle: action.payload.huddle, isPublic };
            yield call(quizzesApiRequest, {
                method: 'POST',
                url: `/game/definition`,
                payload: apiPayload
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.create,
                        type: 'success'
                    }
                })
            );
            yield put(navGoTo(action.payload.huddle ? routes.huddleAll : routes.quizAll));
            yield put(navClearStore());
        } else if (action.type === QuizzesActions.UPDATE) {
            const { id, name, category, description, avatarUrl } = quizDetailsMapper.stateToStore(
                action.payload.fields
            );
            // const questions = yield select(pendingQuestionsSelector);

            const apiPayload = { questions: [], name, category, description, avatarUrl, isHuddle: action.payload.huddle };
            yield call(quizzesApiRequest, {
                method: 'PUT',
                url: `/game/definition/${id}`,
                payload: apiPayload
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.update,
                        type: 'success'
                    }
                })
            );
            yield put(navGoTo(action.payload.huddle ? routes.huddleAll : routes.quizAll));
            yield put(navClearStore());
        } else if (
            [QuizzesActions.DELETE, QuizzesActions.ENABLE, QuizzesActions.DISABLE].includes(
                action.type
            )
        ) {
            let succesMessage = '';
            if (action.type === QuizzesActions.DELETE) {
                const id = yield select(modalSelector);
                yield put(closeModal(ModalsEnum.CONFIRM));
                yield call(quizzesApiRequest, {
                    method: 'DELETE',
                    url: `/game/definition/${id}?isHuddle=${action.payload.huddle}`
                });
                succesMessage = MESSAGES.success.delete;
            } else if (action.type === QuizzesActions.ENABLE) {
                yield call(quizzesApiRequest, {
                    method: 'PUT',
                    url: `/game/definition/${action.payload.id}/enable?isHuddle=${action.payload.huddle}`,
                    payload: {
                        isHuddle: action.payload.huddle
                    }
                });
                succesMessage = MESSAGES.success.enable;
            } else if (action.type === QuizzesActions.DISABLE) {
                yield call(quizzesApiRequest, {
                    method: 'PUT',
                    url: `/game/definition/${action.payload.id}/disable?isHuddle=${action.payload.huddle}`,
                    payload: {
                        isHuddle: action.payload.huddle
                    }
                });
                succesMessage = MESSAGES.success.disable;
            }

            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: succesMessage,
                        type: 'success'
                    }
                })
            );
            const tableQueryParams = yield select(queryParamsSelector);
            yield put(getQuizzes(tableQueryParams, action.payload.huddle));
        }
    } catch (error) {
        const uiError = handleGenerateError(error);
        console.error('Quizzes Generated uiError: ', uiError);
        if (uiError.code === 401) {
            yield put(authLogout());
        } else {
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: uiError.message,
                        type: 'error'
                    }
                })
            );
        }
        if (action.type === QuizzesActions.GET_QUIZ) yield put(hideLoader('quiz'));
        if (action.type === QuizzesActions.GET) yield put(hideLoader('quizzes-table'));
    }
}

export function* quizzCategoriesAPISaga(action: Quizzes.TActions.APICatActions) {
    try {
        if (action.type === QuizzesActions.GET_ALL_CATEGORIES) {
            yield put(showLoader('quizzes-categories'));
            const response = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/category?pageNumber=1&pageSize=100&isHuddle=${action.payload.huddle}`
            });
            yield put(setCategories(response.data, false));
            yield put(hideLoader('quizzes-categories'));
        } else if (action.type === QuizzesActions.GET_CATEGORIES) {
            yield put(showLoader('quizzes-categories'));
            const params = handleQueryParamsData<Quizzes.TCatSortFileds>(action.payload.params);

            const response = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/category?${params}&isHuddle=${action.payload.huddle}`
            });
            yield put(setCategories(response.data, action.payload.huddle));

            yield put(hideLoader('quizzes-categories'));
        } else if (action.type === QuizzesActions.CREATE_CAT) {
            const { name } = action.payload.category as Quizzes.TCategory;
            const apiPayload = { name, isHuddle: action.payload.huddle };
            yield put(closeModal(ModalsEnum.CREATE_CATEGORY));
            yield call(quizzesApiRequest, {
                method: 'POST',
                url: `/game/category`,
                payload: apiPayload
            });

            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.createCat,
                        type: 'success'
                    }
                })
            );

            // const tableQueryParams = yield select(queryParamsCatSelector);
            // yield put(getCategories(tableQueryParams));
            yield put(getAllCategories(action.payload.huddle));
        } else if (action.type === QuizzesActions.UPDATE_CAT) {
            const { id, ...apiPayload } = action.payload.category as Quizzes.TCategory;
            yield put(closeModal(ModalsEnum.UPDATE_CATEGORY));
            yield call(quizzesApiRequest, {
                method: 'PUT',
                url: `/game/category/${id}`,
                payload: {
                    ...apiPayload,
                    isHuddle: action.payload.huddle
                }
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.updateCat,
                        type: 'success'
                    }
                })
            );
            const tableQueryParams = yield select(queryParamsCatSelector);
            yield put(getCategories(tableQueryParams, action.payload.huddle));
        } else if (action.type === QuizzesActions.DELETE_CAT) {
            const id = yield select(modalSelector);
            yield put(closeModal(ModalsEnum.CONFIRM));
            yield call(quizzesApiRequest, {
                method: 'DELETE',
                url: `/game/category/${id}?isHuddle=${action.payload.huddle}`,
                payload: {
                    isHuddle: action.payload.huddle
                }
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.deleteCat,
                        type: 'success'
                    }
                })
            );
            const tableQueryParams = yield select(queryParamsCatSelector);
            yield put(getCategories(tableQueryParams, action.payload.huddle));
        }
    } catch (error) {
        const uiError = handleGenerateError(error);
        console.error('Quizzes Categories Generated uiError: ', uiError);
        yield put(
            showSnackbar({
                opened: true,
                data: {
                    message: uiError.message,
                    type: 'error'
                }
            })
        );
        if (action.type === QuizzesActions.GET_CATEGORIES)
            yield put(hideLoader('quizzes-categories'));
    }
}

export function* quizzQuestionsAPISaga(action: Quizzes.TActions.APIQuestions) {
    try {
        if (action.type === QuizzesActions.GET_QUESTIONS) {
            const response = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/quiz/definition/${action.payload.id}/questions?isHuddle=${action.payload.huddle}`
            });
            yield put(setQuestions(response.data, action.payload.huddle));
        } else if (action.type === QuizzesActions.CREATE_QUESTION) {
            const { quizId, question } = action.payload;
            const apiPayload = questionMapper.stateToStore(question);
            yield call(quizzesApiRequest, {
                method: 'POST',
                url: `/game/quiz/definition/${quizId}/questions`,
                payload: {
                    ...apiPayload,
                    isHuddle: action.payload.huddle
                }
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.createQuestion,
                        type: 'success'
                    }
                })
            );
            // yield put(getQuestions(quizId)); // Watch if this is actually needed
        } else if (action.type === QuizzesActions.UPDATE_QUESTION) {
            const { quizId, question } = action.payload;
            const apiPayload = questionMapper.stateToStore(question);
            yield call(quizzesApiRequest, {
                method: 'PUT',
                url: `/game/quiz/definition/${quizId}/questions/${question.id}`,
                payload: {
                    ...apiPayload,
                    isHuddle: action.payload.huddle
                }
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.updateQuestion,
                        type: 'success'
                    }
                })
            );
        } else if (action.type === QuizzesActions.REORDER_QUESTIONS) {
            const { quizId, questions, huddle, deleteQuestions } = action.payload;

            for (const i in questions) {
                if (questions.hasOwnProperty(i)) {
                    const { id: questionId, ...questionToAdd } = questions[i]
                    const apiPayload = questionMapper.stateToStore(questionToAdd);
                    yield call(quizzesApiRequest, {
                        method: 'POST',
                        url: `/game/quiz/definition/${quizId}/questions`,
                        payload: {
                            ...apiPayload,
                            isHuddle: huddle
                        }
                    });
                }
            };
            for (const i in deleteQuestions) {
                if (deleteQuestions.hasOwnProperty(i)) {
                    // Delete questions
                    yield call(quizzesApiRequest, {
                        method: 'DELETE',
                        url: `/game/quiz/definition/${quizId}/questions/${deleteQuestions[i]}`,
                        payload: {
                            isHuddle: action.payload.huddle
                        }
                    });
                }
            }

            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.reorder,
                        type: 'success'
                    }
                })
            );
            // yield put(getQuestions(quizId)); // Watch if this is actually needed
        } else if (action.type === QuizzesActions.DELETE_QUESTION) {
            const { quizId, questionId } = action.payload;
            yield call(quizzesApiRequest, {
                method: 'DELETE',
                url: `/game/quiz/definition/${quizId}/questions/${questionId}`,
                payload: {
                    isHuddle: action.payload.huddle
                }
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.deleteQuestion,
                        type: 'success'
                    }
                })
            );
            // yield put(getQuestions(quizId)); // Watch if this is actually needed
        }
    } catch (error) {
        const uiError = handleGenerateError(error);
        console.error('Quizzes Questions Generated uiError: ', uiError);
        yield put(
            showSnackbar({
                opened: true,
                data: {
                    message: uiError.message,
                    type: 'error'
                }
            })
        );
    }
}

const quizSelector = (state: IAppState): Quizzes.TState['quiz'] => state.quizzes.quiz
const pendingQuestionsSelector = (state: IAppState): Quizzes.TState['pendingQuestions'] =>
    state.quizzes.pendingQuestions;
const pendingRewardsSelector = (state: IAppState): Quizzes.TState['pendingRewards'] =>
    state.quizzes.pendingRewards;
const queryParamsSelector = (state: IAppState): Quizzes.TQueryParams =>
    state.quizzes.tableQueryParams;
const queryParamsCatSelector = (state: IAppState): Quizzes.TCatQueryParams =>
    state.quizzes.categories.tableQueryParams;
const modalSelector = (state: IAppState): string => state.modal.data;