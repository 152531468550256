import React from 'react';
import { TextField } from '@material-ui/core';
import { useOutlinedStyles } from '../../styles';
import { ValidStatus } from 'types/Enums';

export type OutlinedFieldProps = {
  value: string | null,
  label: string,
  multiline: boolean,
  valid?: ValidStatus,
  maxLength?: number,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const OutlinedTextFields: React.FC<OutlinedFieldProps> = ({ value, label, multiline, valid, maxLength, onChange }) => {
  const classes = useOutlinedStyles()

  return (
    <TextField
      value={value}
      label={label}
      variant="filled"
      fullWidth
      multiline={multiline}
      InputProps={{ classes: classes, className: valid === ValidStatus.INVALID ? 'invalid' : '', disableUnderline: true }}
      inputProps={{ maxLength: maxLength ? maxLength : 524288 }}
      onChange={onChange}
      helperText={maxLength ? `${value ? value.length : 0}/${maxLength}` : false}
    />
  )
}

export default OutlinedTextFields
