import {Redirect, Route, Switch} from "react-router-dom";
import {RouteBuilder, Routes} from "./Utils/RouteBuilder";
import {Loader, SideNav} from "./Components/Shared";
import React, {lazy, Suspense, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IAppState} from "./types";

import LoginPage from './Components/ManageUsers/LoginPage';
import PreviewMeetingPage from "Components/Scheduler/pages/PreviewMeetingPage";
import RedirectPage from "Components/ManageUsers/RedirectPage";
import { ShowType } from "types/Enums";
const UsersPage = lazy(() => import('./Components/ManageUsers/UsersPage'));
const ChangePassPage = lazy(() => import('./Components/ManageUsers/ChangePassPage'));
const QuizzesPage = lazy(() => import('./Components/Quiz/Quiz'));
const QuizPage = lazy(() => import('./Components/Quiz/QuizPage'));
const CategoriesPage = lazy(() => import('./Components/Quiz/CategoriesPage'));

const CalendarHomePage = lazy(() => import('Components/Scheduler/pages/CalendarHomePage'));
const CreateMeetingPage = lazy(() => import('Components/Scheduler/pages/CreateMeetingPage'));
const CreateEventPage = lazy(() => import('Components/Scheduler/pages/CreateEventPage'));
const PreviewInvitationPage = lazy(() => import('Components/Scheduler/pages/PreviewInvitationPage'));


const AppRouter = () => {
    const [defaultRoute, setDefaultRoute] = useState('/') 
    const authenticated = useSelector((state: IAppState) => Boolean(state.auth.user));
    const isCommunity = useSelector((state: IAppState) => state.auth.community);    

    useEffect(() => {
        if (isCommunity === ShowType.COMMUNITY) {
            setDefaultRoute(RouteBuilder.scheduler.calendar.build())
        } else if (isCommunity === ShowType.HUDDLE) {
            setDefaultRoute(RouteBuilder.huddle.categories.build())
        }
    }, [isCommunity])

    if (!authenticated) {
        return (
            <Switch>
                <Route path={Routes.LOGIN} component={LoginPage}/>
                <Route path={RouteBuilder.invitation.id.build()} component={PreviewMeetingPage} />
                <Route path={Routes.AUTH} component={RedirectPage} />
                <Redirect from="/" to={Routes.AUTH}/>
            </Switch>
        );
    }
    return (
        <>
            <SideNav/>
            <Suspense fallback={<Loader text="Loading View"/>}>
                <Switch>
                    <Route path={Routes.USERS} component={UsersPage}/>
                    <Route path={Routes.CHANGE_PASSWORD} component={ChangePassPage}/>
                    <Route path={[
                            RouteBuilder.quiz.all.build(),
                            RouteBuilder.categories.id.build()
                        ]}
                        component={QuizzesPage}
                    />
                    <Route path={RouteBuilder.quiz.categories.build()} component={CategoriesPage}/>
                    <Route
                        path={[
                            RouteBuilder.quiz.manage.id.build(),
                            RouteBuilder.quiz.manage.build()
                        ]}
                        component={QuizPage}
                    />
                    <Route path={[
                            RouteBuilder.huddle.all.build(),
                            RouteBuilder.huddle.categories.id.build()
                        ]}
                        component={QuizzesPage}
                    />
                    <Route path={RouteBuilder.huddle.categories.build()} component={CategoriesPage}/>
                    <Route
                        path={[
                            RouteBuilder.huddle.manage.id.build(),
                            RouteBuilder.huddle.manage.build()
                        ]}
                        component={QuizPage}
                    />
                    <Route path={RouteBuilder.invitation.id.build()} component={PreviewMeetingPage} />
                    <Route path={RouteBuilder.scheduler.calendar.build()} component={CalendarHomePage} />
                    <Route path={RouteBuilder.scheduler.newMeeting.build()} component={CreateMeetingPage} />
                    <Route path={RouteBuilder.scheduler.newEvent.build()} component={CreateEventPage} />
                    <Route path={RouteBuilder.scheduler.editEvent.build()} component={CreateEventPage} />
                    <Route path={RouteBuilder.scheduler.previewInvitation.build()} component={PreviewInvitationPage} />
                    <Redirect from={Routes.SCHEDULER} to={RouteBuilder.scheduler.calendar.build()} />
                    {/* <Redirect from="/" to={Routes.USERS}/> */}
                    <Redirect from="/" to={defaultRoute}/>
                </Switch>
            </Suspense>
        </>
    );
};

export default AppRouter