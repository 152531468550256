import { v4 as uuid } from 'uuid';
import { Quizzes, AFormats } from '../../types/Quizzes';
const QUESTION_TEMPLATE_STORE = {
    id: '',
    quizQuestionType: 'SingleChoice',
    quizQuestionFormat: 'Text',
    questionText: '',
    explanation: '',
    answerChoices: ['', ''],
    correctAnswerChoices: [],
    points: 0,
    maxTime: '00:00:15'
} as Quizzes.TQuestion;

const storeToState = (question: Quizzes.TQuestion): Quizzes.TQuestionFormFields => {
    const answerPoints = question.answerChoices.map((ans, idx) => {
        if (question.correctAnswerChoices.indexOf(idx) !== -1) return question.points;
        return 0;
    });
    const timeArray = question.maxTime.split(':');
    // timeArray[0] hours, timeArray[1] minutes, timeArray[2] seconds
    const time =
        parseInt(timeArray[0]) * 60 * 60 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[2]);
    return {
        id: question.id,
        questionType: question.quizQuestionType,
        questionFormat: question.quizQuestionFormat,
        questionText: question.questionText,
        explanation: question.explanation,
        answerFormat: AFormats.Text,
        answerChoices: question.answerChoices,
        answerPoints: answerPoints,
        correctAnswers: question.correctAnswerChoices,
        time: time
    };
};
const stateToStore = (question: Quizzes.TQuestionFormFields): Quizzes.TQuestion => {
    let points = 0;
    question.answerPoints.forEach((ansPoints) => {
        points += ansPoints;
    });
    const maxTime =
        question.time > 0 ? new Date(question.time * 1000).toISOString().substr(11, 8) : '00:00:00';
    return {
        id: question.id!,
        quizQuestionType: question.questionType,
        quizQuestionFormat: question.questionFormat,
        questionText: question.questionText,
        explanation: question.explanation,
        answerChoices: question.answerChoices,
        correctAnswerChoices: question.correctAnswers,
        points: points,
        maxTime: maxTime
    };
};
export const questionMapper = {
    getStoreTemplate: () => ({ ...QUESTION_TEMPLATE_STORE, id: `TEMP_${uuid()}` }),
    getStateTemplate: () => storeToState({ ...QUESTION_TEMPLATE_STORE, id: `TEMP_${uuid()}` }),
    storeToState,
    stateToStore
};
