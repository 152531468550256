import React, { useMemo, forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { makeStyles, Theme, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        backgroundColor: (selected) => {
            return selected ? theme.backgroundColors?.secondaryBlue : 'transparent';
        },
        borderRight: (selected) => {
            return selected
                ? `4px solid ${theme.backgroundColors?.primaryBlue}`
                : `4px solid transparent`;
        },
        color: (selected) => {
            return selected ? theme.text?.primary : theme.text?.primaryBlue;
        },

        '&:hover': {
            backgroundColor: theme.backgroundColors?.secondaryBlue
        },
        '& .MuiTypography-root': {
            fontWeight: 600
        },
        '& .MuiListItemIcon-root': {
            color: 'inherit',
            minWidth: 20,
            marginRight: theme.spacing(1)
        },
        '& .MuiChip-root': {
            backgroundColor: theme.backgroundColors?.secondaryBlue
        },
        '& .notify-circle': {
            display: 'inline-block',
            width: 0,
            height: 0,
            borderWidth: theme.spacing(0.5),
            borderColor: theme.backgroundColors?.primaryRed,
            borderStyle: 'solid',
            borderRadius: '50%',
            marginRight: 8
        }
    }
}));
interface Props extends RouterLinkProps {
    icon?: IconDefinition;
    primary: string;
    selected?: boolean;
    badge?: React.ReactElement | null;
}

const ListItemLink: React.FC<Props> = ({ icon, primary, to, selected = false, badge, onClick }) => {
    const classes = useStyles(selected);
    const renderLink = useMemo(
        () =>
            forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <ListItem button component={renderLink} className={classes.item} onClick={onClick}>
            {icon ? (
                <ListItemIcon>
                    <FontAwesomeIcon icon={icon} style={{ width: 19, height: 19 }} />
                </ListItemIcon>
            ) : null}
            <ListItemText primary={primary} />
            {badge && badge}
        </ListItem>
    );
};

export default ListItemLink;
