import React, { useState } from 'react';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    useTheme,
    OutlinedInput,
    InputAdornment,
    Button
} from '@material-ui/core';

import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const styles = (theme: Theme) =>
    createStyles({
        searchInput: {
            height: 50,
            width: '100%',
            backgroundColor: '#fff',
            borderRadius: 24,
            borderColor: theme.border?.input,
            fontSize: 15,
            color: theme.text?.primary
        },
        adornedEnd: {
            paddingRight: 0
        },
        adorned: {
            fontSize: 20
        },
        filterButton: {
            width: 120,
            borderRadius: 24,
            margin: theme.spacing(0.7),
            backgroundColor: theme.backgroundColors?.secondaryBlue,
            color: theme.text?.primaryBlue
        }
    });

interface Props extends WithStyles<typeof styles> {
    placehoder?: string;
    handleSubmitSerach: (data: string) => void;
}

const SearchInput: React.FC<Props> = ({ classes, placehoder = 'Search', handleSubmitSerach }) => {
    const theme = useTheme();
    const [value, setValue] = useState('');
    /** TODO => add dropdown with results */
    return (
        <OutlinedInput
            placeholder={placehoder}
            classes={{
                adornedEnd: classes.adornedEnd
            }}
            className={classes.searchInput}
            onChange={(e) => setValue(e.target.value)}
            startAdornment={
                <InputAdornment
                    position="start"
                    classes={{
                        root: classes.adorned
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} color={theme.border?.input} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment
                    position="end"
                    classes={{
                        root: classes.adorned
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        className={classes.filterButton}
                        startIcon={<FontAwesomeIcon icon={faFilter} />}
                        onClick={() => {
                            handleSubmitSerach(value);
                        }}
                    >
                        Filters
                    </Button>
                </InputAdornment>
            }
        />
    );
};

export default withStyles(styles)(SearchInput);
