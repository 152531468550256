import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { Method } from 'axios';
import httpClient, { httpUploadClient } from '../../config/httpClient';

const axiosUserInstance = httpClient

const axiosQuizzesInstance = httpClient

const axiosMeetingsInstance = httpClient

const axiosImageUploadInstance = httpUploadClient

export type TToken = {
    xr_uid: string;
    xr_fn: string;
    xr_em: string;
    xr_ur: string;
    xr_cid: string;
    nbf: number;
    exp: number;
    iss: string;
    aud: string;
};

/**
 * if token doesn't match userId throws new Error('Unable to Authenitacte.')
 * @param accessToken string
 * @param userId string
 */
export const validateToken = (accessToken: string, userId: string) => {
    const decoded = jwtDecode<TToken>(accessToken);
    if (decoded.xr_uid !== userId) {
        throw new Error('Unable to Authenitacte.');
    }
};

export const isTokenExpired = (accessToken: string) => {
    const decoded = jwtDecode<TToken>(accessToken);
    const expirationDelta = moment.unix(decoded.exp).diff(moment(), 'minutes');
    const isExpired = expirationDelta <= 1;
    return isExpired;
};

export const getRefreshTokenInterval = (accessToken: string) => {
    const decoded = jwtDecode<TToken>(accessToken);
    const refreshInterval = moment.unix(decoded.exp).diff(moment.unix(decoded.nbf));
    return refreshInterval;
};

export const getLocalToken = () => {
    return localStorage.getItem('token');
};
export const setAuthToken = (token: string | null) => {
    if (token) {
        axiosUserInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axiosQuizzesInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axiosMeetingsInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axiosImageUploadInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axiosImageUploadInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
        localStorage.setItem('token', token);
    } else {
        delete axiosUserInstance.defaults.headers.common['Authorization'];
        delete axiosQuizzesInstance.defaults.headers.common['Authorization'];
        delete axiosMeetingsInstance.defaults.headers.common['Authorization'];
        delete axiosImageUploadInstance.defaults.headers.common['Authorization'];
        localStorage.removeItem('token');
    }
};

type TInput<TPayload> = {
    method: Method;
    url: string;
    payload?: TPayload | undefined;
};

export const usersApiRequest = <TResponseData, TPayload = null>({
    method,
    url,
    payload
}: TInput<TPayload>) => {
    return axiosUserInstance.request<TResponseData>({ method, url, data: payload });
};

export const quizzesApiRequest = <TResponseData, TPayload = null>({
    method,
    url,
    payload
}: TInput<TPayload>) => {
    return axiosQuizzesInstance.request<TResponseData>({ method, url, data: payload });
};

export const meetingsApiRequest = <TResponseData, TPayload = null>({
    method,
    url,
    payload
}: TInput<TPayload>) => {
    return axiosMeetingsInstance.request<TResponseData>({ method, url, data: payload });
};

export const imageApiRequest = <TResponseData, TPayload = null>({
    method,
    url,
    payload
}: TInput<TPayload>) => {
    return axiosImageUploadInstance.request<TResponseData>({ method, url, data: payload });
};

/* Utils */
export * from './utils';
