import { put, call, select } from 'redux-saga/effects';
import { usersApiRequest, handleQueryParamsData, handleGenerateError } from '../api';
import { showLoader, hideLoader, showSnackbar, getUsers, setUsers, closeModal } from '../actions';

import { UsersActions, Users } from '../../types/Users';
import { IAppState } from '../../types';
import { ModalsEnum } from '../../types/Enums';

const MESSAGES = {
    success: {
        create: 'User Created',
        update: 'User Updated',
        delete: 'User Deleted'
    }
};
const companyIdSelector = (state: IAppState) => state.auth.user?.companyId;

export function* usersAPISaga(action: Users.TActions.APIActions) {
    try {
        if (action.type === UsersActions.GET) {
            yield put(showLoader('users'));
            const params = handleQueryParamsData(action.payload);
            const response = yield call(usersApiRequest, {
                method: 'GET',
                url: `/admin/user?${params}`
            });
            yield put(setUsers(response.data));
            yield put(hideLoader('users'));
        } else if (action.type === UsersActions.GET_UESR) {
            yield call(usersApiRequest, {
                method: 'GET',
                url: `/admin/user/${action.payload}`
            });
        } else if (action.type === UsersActions.CREATE) {
            const companyId = yield select(companyIdSelector);
            const apiPayload = {
                ...action.payload,
                companyId,
                /** TODO => IMPLEMENT REAL Username (as BE requires this field to be unique) */
                userName: `${action.payload.firstName}-${action.payload.lastName}`
            };

            yield put(closeModal(ModalsEnum.CREATE_USER));

            yield call(usersApiRequest, {
                method: 'POST',
                url: `/admin/user`,
                payload: apiPayload
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.create,
                        type: 'success'
                    }
                })
            );
            const tableQueryParams = yield select(queryParamsSelector);
            yield put(getUsers(tableQueryParams));
        } else if (action.type === UsersActions.UPDATE) {
            const { id, ...apiPayload } = action.payload as Users.IUser;

            yield put(closeModal(ModalsEnum.UPDATE_USER));

            yield call(usersApiRequest, {
                method: 'PUT',
                url: `/admin/user/${id}`,
                payload: apiPayload
            });
            const tableQueryParams = yield select(queryParamsSelector);
            yield put(getUsers(tableQueryParams));
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.update,
                        type: 'success'
                    }
                })
            );
        } else if (action.type === UsersActions.DELETE) {
            const id = yield select(modalSelector);
            yield put(closeModal(ModalsEnum.CONFIRM));
            yield call(usersApiRequest, {
                method: 'DELETE',
                url: `/admin/user/${id}`
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: MESSAGES.success.delete,
                        type: 'success'
                    }
                })
            );
            const tableQueryParams = yield select(queryParamsSelector);
            yield put(getUsers(tableQueryParams));
        }
    } catch (error) {
        const uiError = handleGenerateError(error);
        console.error('USERS Generated uiError: ', uiError);
        yield put(
            showSnackbar({
                opened: true,
                data: {
                    message: uiError.message,
                    type: 'error'
                }
            })
        );
        if (action.type === UsersActions.GET) yield put(hideLoader('users'));
    }
}

const queryParamsSelector = (state: IAppState): Users.TQueryParams => state.users.tableQueryParams;
const modalSelector = (state: IAppState): string => state.modal.data;
