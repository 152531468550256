import {Quizzes, QuizzesActions} from "../../../types/Quizzes";

export {setCatQueryParamsQuizzes} from "./actions";
export {setCategories} from "./actions";
export {setQueryParamsQuizzes} from "./actions";
export {setPendingQuestions} from "./actions";
export {setPendingRewards} from "./actions";
export {setQuestions} from "./actions";
export {setQuiz} from "./actions";
export {setQuizzes} from "./actions";
export {deleteQuestion} from "./actions";
export {deleteReward} from "./actions";
export {updateQuestion} from "./actions";
export {reorderQuestions} from "./actions";
export {updateReward} from "./actions";
export {createQuestion} from "./actions";
export {createReward} from "./actions";
export {getQuestions} from "./actions";
export {deleteQuizCat} from "./actions";
export {updateQuizCat} from "./actions";
export {createQuizCat} from "./actions";
export {getCategories} from "./actions";
export {disableQuiz} from "./actions";
export {enableQuiz} from "./actions";
export {deleteQuiz} from "./actions";
export {updatePendingQuestions} from "./actions";
export {updatePendingRewards} from "./actions";
export {updateQuiz} from "./actions";
export {createQuiz} from "./actions";
export {getQuiz} from "./actions";
export {getQuizzes} from "./actions";

const initialState: Quizzes.TState = {
    data: [],
    totalElementsCount: 0,
    totalPagesCount: 0,
    quiz: null,
    pendingQuestions: [],
    pendingRewards: [],
    categories: {
        data: [],
        totalElementsCount: 0,
        totalPagesCount: 0,
        tableQueryParams: {
            searchTerm: '',
            pageSize: 12,
            pageNumber: 0,
            sortBy: '',
            sortDirection: ''
        }
    },
    tableQueryParams: {
        searchTerm: '',
        pageSize: 10,
        pageNumber: 0,
        sortBy: '',
        sortDirection: ''
    },
    tempAvatar: null,
    triviaMeetingId: null
};
const quizzesReducer = (
    state = initialState,
    action: Quizzes.TActions.AllReducer
): Quizzes.TState => {
    switch (action.type) {
        case QuizzesActions.SET:
            return {
                ...state,
                data: action.payload.response.elements,
                totalElementsCount: action.payload.response.totalElementsCount,
                totalPagesCount: action.payload.response.totalPagesCount
            };
        case QuizzesActions.SET_ONE:
            return {
                ...state,
                quiz: action.payload.definition
            };
        case QuizzesActions.SET_QUESTIONS:
            return {
                ...state,
                quiz: {
                    ...state.quiz!,
                    questions: action.payload.questions
                }
            };
        case QuizzesActions.SET_PENDING:
            return {
                ...state,
                pendingQuestions: action.payload.questions
            };
        case QuizzesActions.SET_PENDING_REWARDS:
            return {
                ...state,
                pendingRewards: action.payload.rewards
            };
        case QuizzesActions.SET_QUERY_PARAMS:
            return {
                ...state,
                tableQueryParams: {
                    ...state.tableQueryParams,
                    ...action.payload.params
                }
            };
        case QuizzesActions.SET_CATEGORIES:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: action.payload.response.elements,
                    totalElementsCount: action.payload.response.totalElementsCount,
                    totalPagesCount: action.payload.response.totalPagesCount
                }
            };
        case QuizzesActions.SET_CAT_QUERY_PARAMS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    tableQueryParams: {
                        ...state.categories.tableQueryParams,
                        ...action.payload.params
                    }
                }
            };
        case QuizzesActions.SET_TEMP_IMAGE:
            return {
                ...state,
                tempAvatar: action.payload.data
            };
        case QuizzesActions.SET_GAMECODE:
            return {
                ...state,
                triviaMeetingId: action.payload
            };
        case QuizzesActions.CLEAR_QUIZ:
            return {
                ...state,
                data: [],
                totalElementsCount: 0,
                totalPagesCount: 0,
                quiz: null,
                pendingQuestions: []
            };
        default:
            return state;
    }
};

export default quizzesReducer;
export {catsPageSelector} from "./selectors";
export {quizPageSelector} from "./selectors";
export {quizToEditSelector} from "./selectors";
export {quizzesPageSelector} from "./selectors";
export {quizAvatarSelector} from "./selectors";