import { RolesEnum, UsersTypesEnum, ModalsEnum } from './Enums';
import { IActionPayload, IActionMeta, ITableQueryParams } from './utils';

export { RolesEnum } from './Enums';
/** -> Users Action Types */
export const UsersActions = UsersTypesEnum;

export declare namespace Users {
    /** -> API Error */
    export type TAPIError = {
        ErrorCode: number;
        ErrorDescription: string;
        ErrorDetails: {
            [key: string]: string;
        };
    };

    export type TError = {
        code: number;
        message: string;
    };

    /** TODO => switch from interface to type */
    export interface IUser {
        id: string;
        firstName?: string;
        lastName?: string;
        companyId?: string;
        companyEmployeeId?: string;
        userName?: string;
        email: string;
        roles: RolesEnum | number | string;
        avatarUrl?: string;
    }

    export type TUsersTableRecord = {
        employeeId: string;
        name: string;
        email: string;
        role: RolesEnum | number | string;
        allFields: IUser;
    };

    /** -> Payload from User Fetch Success */
    export type IUserFetchResponse = {
        elements: IUser[];
        totalElementsCount: number;
        totalPagesCount: number;
    };

    export type TState = {
        data: IUser[];
        totalElementsCount: number;
        totalPagesCount: number;
        tableQueryParams: TQueryParams;
    };

    export type TSortFileds = keyof IUser;
    export type TQueryParams = ITableQueryParams<TSortFileds>;

    export namespace TActions {
        export type GetAll = IActionPayload<UsersTypesEnum.GET, TQueryParams>;
        export type GetUser = IActionPayload<UsersTypesEnum.GET_UESR, string>;
        export type Create = IActionMeta<
            UsersTypesEnum.CREATE,
            IUser,
            {
                modal: ModalsEnum;
            }
        >;
        export type Update = IActionMeta<
            UsersTypesEnum.UPDATE,
            IUser,
            {
                modal: ModalsEnum;
            }
        >;
        export type Delete = IActionMeta<
            UsersTypesEnum.DELETE,
            null,
            {
                modal: ModalsEnum;
            }
        >;

        export type Set = IActionPayload<UsersTypesEnum.SET, IUserFetchResponse>;
        export type SetQueryParams = IActionPayload<
            UsersTypesEnum.SET_QUERY_PARAMS,
            Partial<TQueryParams>
        >;

        export type AllReducer = Set | SetQueryParams;
        export type APIActions = GetAll | GetUser | Create | Update | Delete;
    }
}
