import React from 'react';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    Dialog,
    DialogProps,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button
} from '@material-ui/core';
import ModalTitle from '../ModalTitle/ModalTitle';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: 15,
            paddingTop: 12,
            paddingBottom: 22,
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.backgroundColors?.primary
        },
        dialogContent: {},
        dialogActions: {
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
        buttonSubmit: {
            color: theme.text?.primaryRed,
            borderColor: theme.text?.primaryRed
        }
    });

interface Props extends WithStyles<typeof styles>, Omit<DialogProps, 'classes'> {
    modalContext: 'user' | 'quiz' | 'quiz-category' | 'meeting';
    onConfirm: () => void;
    onDismiss: () => void;
}

const modalTextsMap = {
    user: {
        title: 'Remove User',
        description:
            'Are you sure you want to remove this user? If you remove them, they will permanently lose your profile, analytics, and events.',
        btnDismissText: 'Keep User',
        btnSubmitText: 'Remove User'
    },
    quiz: {
        title: 'Remove Quiz',
        description:
            '',
        btnDismissText: 'Keep Quiz',
        btnSubmitText: 'Remove Quiz'
    },
    'quiz-category': {
        title: 'Remove Quiz Category',
        description:
            '',
        btnDismissText: 'Keep Category',
        btnSubmitText: 'Remove Category'
    },
    meeting: {
        title: 'Are you sure you want to delete this meeting?',
        description: 'If you choose to delete it, you will not be able to access or restore it later.',
        btnDismissText: 'No, take me back',
        btnSubmitText: 'Yes, delete'
    }
};

const ConfirmModal: React.FC<Props> = ({ modalContext, classes, open, onConfirm, onDismiss }) => {
    const { title, description, btnDismissText, btnSubmitText } = modalTextsMap[modalContext];
    return (
        <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="confirm-delete-title"
            aria-describedby="confirm-delete-description"
            PaperProps={{
                classes: {
                    root: classes.paper
                }
            }}
        >
            <ModalTitle titleId="confirm-delete-title" title={title} handleClose={onDismiss} />
            <DialogContent className={classes.dialogContent}>
                <DialogContentText id="confirm-delete-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onDismiss} color="primary" variant="text">
                    {btnDismissText}
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="outlined"
                    className={classes.buttonSubmit}
                    autoFocus
                >
                    {btnSubmitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(ConfirmModal);
