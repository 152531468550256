import { useState, useEffect } from 'react';
import useIsMounted from './useIsMounted';

export type TErrors<S> = {
    [key in keyof S]?: string;
};

export type THandleInputFn = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
export type TSetInputFn = (input: { name: string; value: any }) => void;
type TMessage = string;
export type TValidations<S> = {
    [key in keyof S]: {
        pattern?: {
            value: RegExp;
            message: TMessage;
        };
        required?: boolean | TMessage;
        validate?: (value: any, state: S) => TMessage;
    };
};

export const useForm = <S extends { [key: string]: any }>(
    initalState: S,
    validations: TValidations<S>,
    propsData?: S | null
): {
    values: S;
    errors: TErrors<S>;
    handleInput: THandleInputFn;
    setInput: TSetInputFn;
    isFormValid: () => boolean;
    setInitalValues: () => void;
} => {
    const isMounted = useIsMounted();
    const [stateValues, setValues] = useState<S>(initalState);
    const [errors, setErrors] = useState<TErrors<S>>({});

    useEffect(() => {
        if (propsData && Object.keys(propsData).length && isMounted()) {
            let data = {} as S;
            Object.keys(initalState).forEach((key: keyof S) => {
                data[key] = propsData[key];
            });
            setValues(data);
        }
    }, [propsData, initalState, isMounted]);

    const validateField = (name: keyof S, value: any, state: S): string => {
        const rules = validations[name];
        if (rules) {
            if (rules.required) {
                if (!value.trim()) {
                    return typeof rules.required === 'string'
                        ? rules.required
                        : 'Field is required';
                }
            }
            if (rules.pattern && !new RegExp(rules.pattern.value).exec(value)) {
                return rules.pattern.message || 'Invalid input';
            }
            if (rules.validate && typeof rules.validate === 'function') {
                const error = rules.validate(value, state);
                if (error) return error;
            }
        }

        return '';
    };

    const handleInput: THandleInputFn = (e) => {
        const { name, value } = e.target;
        setInput({ name, value });
    };

    const setInput: TSetInputFn = ({ name, value }) => {
        setValues((state) => ({
            ...state,
            [name]: value
        }));
        setErrors((state) => ({
            ...state,
            [name]: validateField(name as keyof S, value, stateValues)
        }));
    };

    const setInitalValues = (): void => {
        setValues(initalState)
    };

    const isFormValid = () => {
        const hasErrors = Object.keys(validations).some((name) =>
            Boolean(validateField(name, stateValues[name], stateValues))
        );
        return !hasErrors;
    };
    return { values: stateValues, errors, handleInput, setInput, isFormValid, setInitalValues };
};
