import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COMMUNITY_MEETING_URL } from 'config/constants';
import { faCalendarAlt, faMapMarkerAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';

import moment from 'moment-timezone';
import 'moment-duration-format';

import { VideoServiceProviders, TimeZones } from '../../constants';
import { durationsToMinutes } from '../../redux/mapper';
import useStyles from './styles';
import RSVPModal from '../RSVPModal';
import { respondInvitation } from '../../redux/actions';
import { meetingsListSelector } from '../../redux';

export type EventPreviewProps = {
  id: string | null,
  code: string | null,
  logoImageData: string | null,
  heroImageData: string | null,
  eventName: string,
  eventDescription: string | null,
  backgroundColor: string,
  textColor: string,
  accentColor: string,
  buttonTextColor: string,
  provider: string,
  selectedDate: Date | null,
  selectedTime: string,
  selectedDuration: string,
  selectedTimeZone: string,
  participants: string[] | null,
  previewShow: boolean
}

const EventPreview: React.FC<EventPreviewProps> = ({
  id,
  code,
  logoImageData,
  heroImageData,
  eventName,
  eventDescription,
  backgroundColor,
  textColor,
  accentColor,
  buttonTextColor,
  provider,
  selectedDate,
  selectedTime,
  selectedDuration,
  selectedTimeZone,
  previewShow
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rsvpOpen, setRSVPOpen] = useState(false);
  const [showRSVP, setShowRSVP] = useState(false);
  const [mTime, setMTime] = useState(false);
  const [leftHours, setLeftHours] = useState('');
  const { rsvpSent } = useSelector(meetingsListSelector);

  const meetingTime = () => {
    if (selectedTime === '' || selectedDuration === '') {
      return '';
    }

    const str = moment(selectedDate).format('YYYY-MM-DD') + ' ' + selectedTime;
    const start = moment(str, 'YYYY-MM-DD h:mm A').tz(selectedTimeZone);
    const end = moment(str, 'YYYY-MM-DD h:mm A').tz(selectedTimeZone).add(durationsToMinutes(selectedDuration), 'minutes');

    let timeStr = start.format('MMMM Do h:mm A') + ' - ' + end.format('h:mm A');
    if (selectedTimeZone) {
      const timeZone = TimeZones.filter(zone => zone.value === selectedTimeZone)[0];
      if (timeZone) {
        timeStr += ` (${timeZone.text})`;
      } else {
        timeStr += ` (${selectedTimeZone})`;
      }
    }

    return timeStr;
  }

  const startTime = () => {
    const date = moment(moment(selectedDate).format('yyyy MM D ') + selectedTime, 'yyyy MM D h:mm A');
    return date.format();
  }

  const endTime = () => {
    const date = moment(moment(selectedDate).format('yyyy MM D ') + selectedTime, 'yyyy MM D h:mm A').add(durationsToMinutes(selectedDuration), 'minutes');
    return date.format();
  }

  const handleRSVP = useCallback((firstName, lastName, email) => {
    if (email && id) {
      dispatch(respondInvitation(id, {
        id,
        email,
        action: 'accept',
        invitationToken: null
      }));
      setRSVPOpen(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (previewShow) {
      const afterDate = moment(moment(selectedDate).format('yyyy MM D ') + selectedTime, 'yyyy MM D h:mm A').tz(selectedTimeZone);
      if (afterDate) {
        const diff = afterDate.diff(moment().tz(selectedTimeZone));
        const duration = moment.duration(diff).format("d[d] h[h] m[m]", { trim: 'both' });
        setLeftHours(duration);
      }
    }
  }, [leftHours, selectedDate, selectedTime, selectedTimeZone, previewShow]);

  useEffect(() => {
    const dateStr = moment(moment(selectedDate).format('YYYY MM DD ') + selectedTime, 'YYYY MM DD h:mm A').format('YYYY-MM-DDTHH:mm:ss');
    const startTime = moment(dateStr);
    const endTime = moment(dateStr).add(durationsToMinutes(selectedDuration), 'minutes');
    const mTime = moment.tz(selectedTimeZone).isAfter(startTime) && moment.tz(selectedTimeZone).isBefore(endTime);

    setMTime(mTime);

  }, [selectedDate, selectedDuration, selectedTime, selectedTimeZone]);

  useEffect(() => {
    if (rsvpSent.id && rsvpSent.sent) {
      setShowRSVP(true);
    } else {
      setShowRSVP(false);
    }
  }, [rsvpSent]);

  const handleJoinMeeting = useCallback(() => {
    window.open(`${COMMUNITY_MEETING_URL}/room/${code}`, '_blank');
  }, [id]);

  return (
    <div className={previewShow ? classes.previewContainer : classes.showContainer} style={{ backgroundColor: backgroundColor }}>
      <div className={classes.heroContainer}>
        <div className={classes.heroPreview} style={{ backgroundImage: heroImageData ? `url(${heroImageData})` : 'none' }}>
      </div>
      </div>
      <div className={classes.eventPreview}>
        <div className={classes.logoPreview} style={{ backgroundImage: logoImageData ? `url(${logoImageData})` : 'none' }}></div>
        <div className={classes.eventDetailPreview}>
          <Typography variant="h1" style={{ color: textColor }}>{eventName}</Typography>
          <div style={{ color: textColor, display: 'flex', marginBottom: 12, alignItems: 'center' }}>
            <FontAwesomeIcon icon={faCalendarAlt} style={{ width: 20, height: 20, color: accentColor, marginRight: 12 }}/>
            {meetingTime()}
              <AddToCalendar
                rootClass={classes.addCalendarRoot}
                buttonWrapperClass={classes.addCalendarWrapper}
                dropdownClass={classes.calendarDropdown}
                buttonClassOpen={classes.calButton}
                buttonClassClosed={classes.calButton}
                event={{ title: eventName, description: eventDescription ? eventDescription : '', location: '', startTime: startTime(), endTime: endTime(), url: `${COMMUNITY_MEETING_URL}/room/${id}` }}
                buttonLabel="+ Add to calendar"
                buttonTemplate={{ textOnly: 'none' }}
                displayItemIcons={false}
              />
          </div>
          <Typography style={{ color: textColor }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ width: 20, height: 20, color: accentColor }}/>
            Virtual Event
          </Typography>
        </div>
        <div className={classes.invitePreview} style={{ borderColor: accentColor }}>
          <div>
            <Typography style={{ color: textColor }}>You have been invited!</Typography>
            <Typography style={{ color: textColor }}>Will you attend?</Typography>
          </div>
          {(!showRSVP && !mTime) &&
            <Button variant="contained" style={{ backgroundColor: accentColor, color: buttonTextColor }} onClick={() => previewShow ? setRSVPOpen(true) : {}}>JOIN US</Button>
          }
          {(showRSVP || mTime) &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={classes.rsvpEvent}>
                <FontAwesomeIcon icon={faCheck} style={{ width: 12, height: 9, color: 'white', marginRight: 6 }}/>
                Going!
              </div>
              {!mTime &&
                <Button variant="text" color="primary">Event starts in {leftHours}</Button>
              }
              {mTime &&
                <Button variant="contained" color="primary" onClick={handleJoinMeeting}>Join Us</Button>
              }
            </div>
          }
        </div>
        <div>
          <Typography style={{ color: textColor, whiteSpace: 'pre-line' }}>{eventDescription}</Typography>
        </div>
      </div>
      {provider &&
        <div className={classes.providerPreview}>
          <Typography>POWERED BY</Typography>
          <div>
            <img src={VideoServiceProviders.filter(p => p.name === provider)[0].icon} alt="powered-by" />
            <Typography>{provider}</Typography>
          </div>
        </div>
      }
      <RSVPModal open={rsvpOpen} onClose={() => setRSVPOpen(false)} onRSVP={handleRSVP} />
    </div>
  )
}

export default EventPreview;