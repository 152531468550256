import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loader } from 'Components/Shared';
import { ssoLogin } from '../../../redux/actions';
import { parse } from 'query-string';

import useStyles from './styles';
import { ShowType } from 'types/Enums';

const RedirectPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const getDomain = () => {
    const domain = window.location.hostname;
    if (domain.indexOf('admin-console.') > -1) {
      return domain.replace('admin-console', 'admin');
    }
    return null;
  }

  const logout = useCallback(() => {
    const domain = getDomain();
    if (domain) {
      window.location.replace(`https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=https://${domain}/`);
    } else {
      window.location.replace('https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=https://admin.futureofawesometour.com/');
    }
  }, []);

  const redirectToDefault = useCallback(() => {
    const domain = getDomain();
    if (domain) {
      window.location.replace(`https://${domain}/`);
    } else {
      logout();
    }
  }, [logout]);

  useEffect(() => {
    const params = parse(location.search);

    if (params && params.code) {
      try {
        const data = JSON.parse(atob(params['code'] as string));
        const valid = validateEmail(data.email);

        if (data.email && valid) {
          dispatch(ssoLogin({
            ...data,
            community: params['app'] === 'xc' ? ShowType.COMMUNITY : ShowType.HUDDLE
          }));
        } else {
          redirectToDefault();
        }
      } catch (error) {
        redirectToDefault();
      }
    } else {
      redirectToDefault();
    }
  }, [dispatch, location.search, logout, redirectToDefault]);

  return (
    <div className={classes.root}>
      <Loader text="Authenticating" />
    </div>
  );
}

export default RedirectPage;
